import { Typography } from "antd";
import { GiProfit } from "react-icons/gi";
import { AiOutlineApi } from "react-icons/ai";
import { BiTransferAlt } from "react-icons/bi";
import { RiFileList3Line } from "react-icons/ri";
import { HiOutlineCalculator } from "react-icons/hi";
import { isMobile } from "../../utils";

export const itensExterior = [
  {
    id: "darf",
    premium: true,
    showToBasic: false,
    link: "/xpinvestimentos/exterior-historic",
    content: <Typography>Calculadora de DARF</Typography>,
    description: <Typography>Cálculo de IR e mensal para o ano de 2023</Typography>,
    icon: (
      <HiOutlineCalculator
        className="no-fill"
        color="var(--ant-primary-color)"
      />
    ),
  },
  // {
  //   premium: true,
  //   id: "anual-report",
  //   link: "/xpinvestimentos/exterior-report",
  //   content: <Typography>Informe de rendimentos (2022)</Typography>,
  //   description: <Typography>Relatório para declaração do IRPF</Typography>,
  //   icon: (
  //     <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
  //   ),
  // },
  {
    id: "report",
    premium: true,
    link: "/xpinvestimentos/exterior-report",
    content: (
      <Typography>
       Informe de rendimentos
      </Typography>
    ),
    description: (
      <Typography>Relatórios auxiliares para declaração de IR</Typography>
    ),
    icon: (
      <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
    ),
  },
  // {
  //   id: "report",
  //   premium: true,
  //   link: "/xpinvestimentos/exterior-report",
  //   content: (
  //     <Typography>
  //       Relatórios {!isMobile() && "(padrão Receita Federal)"}
  //     </Typography>
  //   ),
  //   description: (
  //     <Typography>Relatórios auxiliares para declaração de IR</Typography>
  //   ),
  //   icon: (
  //     <RiFileList3Line className="no-fill" color="var(--ant-primary-color)" />
  //   ),
  // },
  // {
  //   id: "dividendos",
  //   premium: true,
  //   link: "/xpinvestimentos/exterior-dividendos",
  //   content: <Typography>Dividendos</Typography>,
  //   icon: <GiProfit className="no-fill" color="var(--ant-primary-color)" />,
  //   description: <Typography>Gestão dos dividendos recebidos</Typography>,
  // },
  {
    id: "insert-manual",
    premium: false,
    link: "/xpinvestimentos/transferencia-custodia",
    content: <Typography>Transferência de custódia</Typography>,
    icon: (
      <BiTransferAlt className="no-fill" color="var(--ant-primary-color)" />
    ),
    // description: <Typography>Gestão de transferências de custódia</Typography>,
  },
  // {
  //   id: "integration",
  //   link: "/xpinvestimentos/exterior-integration",
  //   content: <Typography>Integração com XP</Typography>,
  //   icon: <AiOutlineApi className="no-fill" color="var(--ant-primary-color)" />,
  // },
];
