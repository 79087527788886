import { Button } from "@mui/material";
import { Col, Modal, Row } from "antd";

export function ModalConfirmDesvinculo({
  action,
  closeFn,
  visible,
}: {
  action: () => void;
  visible: boolean;
  closeFn: () => void;
}) {
  function handleClick() {
    action();
    closeFn();
  }

  return (
    <Modal
      title="Desvincular escritório"
      visible={visible}
      onCancel={closeFn}
      closable={true}
      footer={
        <Row gutter={[16, 16]} style={{ marginBottom: "16px" }}>
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => {
                handleClick();
              }}>
              Sim
            </Button>
          </Col>
          <Col xs={24} sm={12}>
            <Button
              fullWidth
              size="large"
              color="secondary"
              variant="contained"
              onClick={() => {
                closeFn();
              }}>
              Não
            </Button>
          </Col>
        </Row>
      }>
      <Row>
        <Col>Deseja desvincular o escritório?</Col>
      </Row>
    </Modal>
  );
}
