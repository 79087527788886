import moment from "moment";
import { Typography } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import React, { Dispatch, SetStateAction } from "react";
import { AiOutlineMinusCircle, AiOutlinePlusCircle } from "react-icons/ai";
import Loading from "../../Loading";
import { PosicaoCardStyled } from "./styles";
import { Posicao } from "../../../contexts/CarteiraContext";
import { CustoMedioModal } from "../../../constants/darfBolsa";
import { formatCurrency, numberToPercentageWallet } from "../../../utils";
import {
  Area,
  AreaChart,
  CartesianGrid,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from "recharts";

const negativeToPositive = (n: number) => (n < 0 ? n * -1 : n);

const signal = (n: number) => (n > 0 ? "+" : n < 0 ? "-" : "");

const className = (n: number) => (n === 0 ? "" : n > 0 ? "success" : "error");

export const LucroPrejuizo: React.FC<{
  n?: number;
  p?: number;
  cotacao?: number;
}> = ({ n, p, cotacao }) => {
  // console.log({n, p})

  return (
    <span className={className(n || 0)}>
      {signal(n || 0)}
      {formatCurrency(negativeToPositive(n || 0))} ({signal(n || 0)}
      {numberToPercentageWallet(negativeToPositive(p || 0))})
      {cotacao && (
        <span className={`valorUSD ${className(n || 0)}`}>
          {signal(n || 0)}
          {formatCurrency(negativeToPositive(n ? n / cotacao : 0), "US$ ")}
        </span>
      )}
    </span>
  )  
};

export const HidenValue: React.FC<{
  block?: boolean;
  strong?: boolean;
  currency?: boolean;
}> = ({ block, strong, currency = true }) => {
  const value = `${currency ? "R$ " : ""}****`;
  const content = strong ? <strong>{value}</strong> : value;
  return block ? <div>{content}</div> : <span>{content}</span>;
};

interface PosicaoCardProps {
  key: string;
  posicao?: Partial<Posicao>;
  hideValues?: boolean;
  setHelpModal: Dispatch<SetStateAction<any>>;
  details?: any;
  loading?: boolean;
  cotacaoVenda?: number;
}

export const PosicaoHeader = (
  posicao?: Partial<Posicao>,
  hideValues?: boolean
) => {
  return (
    <div className="header">
      <div className="title" style={{ width: "20px", flexGrow: 1 }}>
        {posicao?.codigo || "GOOGL"}
        <span
          className={
            posicao?.categoria === "Bonds"
              ? className(posicao?.lucroPrejuizoTotal || 0)
              : className(posicao?.lucroPrejuizoDiario || 0)
          }
          hidden={posicao?.precoAtual ? false : true}>
          {posicao?.categoria === "Bonds"
            ? signal(posicao?.lucroPrejuizoTotal || 0)
            : signal(posicao?.lucroPrejuizoDiario || 0)}{" "}
          {numberToPercentageWallet(
            posicao?.categoria === "Bonds"
              ? negativeToPositive(posicao?.lucroPrejuizoTotalPercentual || 0)
              : negativeToPositive(posicao?.lucroPrejuizoDiarioPercentual || 0)
          )}
        </span>
      </div>
      <div className="posicao">
        {hideValues ? (
          <HidenValue />
        ) : (
          (posicao?.precoAtual || posicao?.posicao)
          ? formatCurrency(
            posicao?.categoria === "TesouroDireto"
              ? posicao?.precoAtual || 0
              : posicao?.posicao || 0
          )
          : formatCurrency(negativeToPositive(posicao?.lucroPrejuizoTotal || 0))
        )}
      </div>
    </div>
  );
};

export const PosicaoBody = (
  posicao: Partial<Posicao>,
  setHelpModal: Dispatch<SetStateAction<any>>,
  hideValues?: boolean,
  details?: any,
  loading?: boolean,
  cotacaoVenda?: number
) => {
  return (
    <>
      <div>
        <span>Quantidade:</span>
        <span>
          {hideValues ? <HidenValue currency={false} /> : posicao.quantidade}
        </span>
      </div>
      <div hidden={posicao?.precoAtual ? false : true}>
        {posicao.categoria === "TesouroDireto" ? (
          <span>Valor atualizado:</span>
        ) : (
          <span>Preço atual:</span>
        )}
        <span>
          {hideValues ? (
            <HidenValue />
          ) : (
            formatCurrency(posicao.precoAtual || 0)
          )}
          {cotacaoVenda && (
            <span className="valorUSD">
              {formatCurrency(
                posicao.precoAtual ? posicao.precoAtual / cotacaoVenda : 0,
                "US$ "
              )}
            </span>
          )}
        </span>
      </div>
      <div>
        {posicao?.loan?.typeLoan === "Tomador" && (
          <>
            <span>Preço de venda:</span>
            <span>
              {hideValues ? (
                <HidenValue />
              ) : (
                formatCurrency(posicao.precoVenda || 0)
              )}
            </span>
          </>
        )}
      </div>
      {posicao.hasPosition2019 && posicao?.loan?.typeLoan !== "Tomador" && (
        <div
          style={{
            cursor:
              posicao.custoMedio === 0 || !posicao.custoMedio
                ? "pointer"
                : "default",
          }}
          onClick={() =>
            posicao.custoMedio === 0 || !posicao.custoMedio
              ? setHelpModal(CustoMedioModal)
              : null
          }>
          <span>Custo médio de compra: </span>
          <span>
            {posicao.custoMedio === 0 || !posicao.custoMedio ? (
              <InfoCircleOutlined className="info-circle-red" />
            ) : (
              formatCurrency(posicao.custoMedio)
            )}
            {cotacaoVenda && posicao.custoMedio && (
              <span className="valorUSD">
                {formatCurrency(
                  posicao.avgPriceUsd ? posicao.avgPriceUsd : 0,
                  "US$ "
                )}
              </span>
            )}
          </span>
        </div>
      )}
      {!posicao.hasPosition2019 && posicao?.loan?.typeLoan !== "Tomador" && (
        <div>
          {posicao.categoria === "TesouroDireto" ? (
            <span>Valor Aplicado:</span>
          ) : (
            <span>Custo médio de compra:</span>
          )}
          <span>
            {posicao.custoMedio === 0 || !posicao.custoMedio ? (
              "N/A"
            ) : hideValues ? (
              <HidenValue />
            ) : (
              formatCurrency(posicao.custoMedio)
            )}
            {cotacaoVenda && posicao.custoMedio && (
              <span className="valorUSD">
                {formatCurrency(
                  posicao.avgPriceUsd ? posicao.avgPriceUsd : 0,
                  "US$ "
                )}
              </span>
            )}
          </span>
        </div>
      )}
      {posicao.categoria !== "TesouroDireto" &&
        posicao.categoria !== "Bonds" && (
          <div hidden={posicao?.precoAtual ? false : true}>
            <span>Lucro/prejuízo diário: </span>
            {hideValues ? (
              <HidenValue />
            ) : (
              <LucroPrejuizo
                n={posicao.lucroPrejuizoDiario}
                p={posicao.lucroPrejuizoDiarioPercentual}
                cotacao={cotacaoVenda}
              />
            )}{" "}
          </div>
        )}
      {posicao.hasPosition2019 && (
        <div
          style={{
            cursor:
              posicao.lucroPrejuizoTotal === 0 || !posicao.lucroPrejuizoTotal
                ? "pointer"
                : "default",
          }}
          onClick={() =>
            posicao.lucroPrejuizoTotal === 0 || !posicao.lucroPrejuizoTotal
              ? setHelpModal(CustoMedioModal)
              : null
          }>
          <span>Lucro/prejuízo total:</span>{" "}
          {posicao.lucroPrejuizoTotal === 0 || !posicao.lucroPrejuizoTotal ? (
            <InfoCircleOutlined className="info-circle-red" />
          ) : (
            <LucroPrejuizo
              n={posicao.lucroPrejuizoTotal}
              p={posicao.lucroPrejuizoTotalPercentual}
              cotacao={cotacaoVenda}
            />
          )}
        </div>
      )}
      {!posicao.hasPosition2019 && (
        <div hidden={posicao?.precoAtual ? false : true}>
          <span>Lucro/prejuízo total: </span>
          {posicao.custoMedio === 0 || !posicao.custoMedio ? (
            "N/A"
          ) : hideValues ? (
            <HidenValue />
          ) : (
            <LucroPrejuizo
              n={posicao.lucroPrejuizoTotal}
              p={posicao.lucroPrejuizoTotalPercentual}
              cotacao={cotacaoVenda}
            />
          )}
        </div>
      )}
      {details &&
        (details?.marketCap !== "N/A" ||
          details?.beta !== "N/A" ||
          details?.peRatio !== "N/A" ||
          details?.earningDate !== "N/A" ||
          details?.dividendYield !== "N/A" ||
          details?.targetPrice !== "N/A" ||
          details?.enterpriseValueRevenue !== "N/A" ||
          details?.enterpriseValueEbitda !== "N/A") && (
          <>
            <hr className="hr-datails" />
            <div className="row-section">
              <div className="info-section">
                <Typography.Title level={5} className="info-title">
                  Negociação
                </Typography.Title>
                <div>
                  <span>Captalização de mercado:</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : (
                      <>
                        {formatCurrency(
                          Number(details?.marketCap || 0) / 1000000000
                        )}{" "}
                        B
                      </>
                    )}
                  </span>
                </div>
                <div>
                  <span>Preço-alvo da ação:</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : typeof details?.targetPrice === "string" ? (
                      details?.targetPrice
                    ) : (
                      formatCurrency(details?.targetPrice)
                    )}
                  </span>
                </div>
                <div>
                  <span>Dividend yield (1YFW):</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : typeof details?.dividendYield === "string" ? (
                      details?.dividendYield
                    ) : (
                      `${(details?.dividendYield * 100)?.toFixed(2)}%`
                    )}
                  </span>
                </div>
                <div>
                  <span>Divulgação de resultados:</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : (
                      details?.earningDate
                    )}
                  </span>
                </div>
                <Typography.Title level={5} className="info-title">
                  Avaliação
                </Typography.Title>
                {/* <div>
                  <span>Beta:</span>
                  <span>
                    {hideValues ? <HidenValue currency={false} /> : typeof details?.beta !== 'string' ? details?.beta?.toFixed(2) : details?.beta}
                  </span>
                </div> */}
                <div>
                  <span>EV/Receita (1YFW):</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : (
                      `${
                        typeof details?.enterpriseValueRevenue !== "string"
                          ? `${details?.enterpriseValueRevenue?.toFixed(1)} x`
                          : details?.enterpriseValueRevenue
                      }`
                    )}
                  </span>
                </div>
                <div>
                  <span>EV/EBITDA (1YFW):</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : (
                      `${
                        typeof details?.enterpriseValueEbitda !== "string"
                          ? `${details?.enterpriseValueEbitda?.toFixed(1)} x`
                          : details?.enterpriseValueEbitda
                      }`
                    )}
                  </span>
                </div>
                <div>
                  <span>P/E (1YFW):</span>
                  <span>
                    {hideValues ? (
                      <HidenValue currency={false} />
                    ) : (
                      `${
                        typeof details?.peRatio !== "string"
                          ? `${details?.peRatio?.toFixed(1)}`
                          : details?.peRatio
                      } x`
                    )}
                  </span>
                </div>
              </div>
              {details?.chart?.length && (
                <div className="chart-section">
                  <ResponsiveContainer width="100%" height={200}>
                    <AreaChart
                      data={details.chart}
                      margin={{ top: 10, right: 10, left: 0, bottom: 0 }}>
                      <defs>
                        <linearGradient
                          id="colorUv"
                          x1="0"
                          y1="0"
                          x2="0"
                          y2="1">
                          <stop
                            offset="5%"
                            stopColor="#d49b00"
                            stopOpacity={0.8}
                          />
                          <stop
                            offset="95%"
                            stopColor="#d49b00"
                            stopOpacity={0}
                          />
                        </linearGradient>
                      </defs>
                      <XAxis
                        dataKey="date"
                        tickCount={4}
                        type="category"
                        interval={80}
                        tickFormatter={(v) => {
                          const date = new Date(v);
                          return `${date.getMonth() + 1}/${date.getFullYear()}`;
                        }}
                      />
                      <YAxis orientation="right" />
                      <CartesianGrid strokeDasharray="3 3" />
                      <Tooltip
                        content={({ payload, label }) => (
                          <div className="chart-tooltip">
                            <text>
                              {moment(new Date(label)).format("DD/MM/YYYY")}
                            </text>
                            <text>
                              Cotação:{" "}
                              {formatCurrency(Number(payload?.[0]?.value || 0))}
                            </text>
                          </div>
                        )}
                      />
                      <Area
                        type="monotone"
                        dataKey="price"
                        stroke="#d49b00"
                        fillOpacity={1}
                        fill="url(#colorUv)"
                      />
                    </AreaChart>
                  </ResponsiveContainer>
                </div>
              )}
            </div>
          </>
        )}
      {loading && (
        <div style={{ height: "150px" }}>
          <Loading />
        </div>
      )}
    </>
  );
};

export const PosicaoCard = ({
  key,
  posicao,
  hideValues,
  setHelpModal,
  details,
  loading,
  cotacaoVenda,
}: PosicaoCardProps) => (
  <PosicaoCardStyled key={key} header={PosicaoHeader(posicao, hideValues)}>
    {posicao
      ? PosicaoBody(
          posicao,
          setHelpModal,
          hideValues,
          details,
          loading,
          cotacaoVenda
        )
      : null}
  </PosicaoCardStyled>
);

export const ExpandIcon = ({ isActive }: { isActive?: boolean }) =>
  isActive ? (
    <AiOutlineMinusCircle
      style={{
        fontSize: "22px",
        color: "var(--ant-primary-color)",
      }}
    />
  ) : (
    <AiOutlinePlusCircle
      style={{
        fontSize: "22px",
        color: "var(--ant-primary-color)",
      }}
    />
  );
